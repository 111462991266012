import * as THREE from 'three';
// import { createChemFlask } from './createChemFlask';
import { textToSpeech } from '../../../common/TabletFunctionality/textToSpeech';
import { Pagination } from '../../../common/TabletFunctionality/Pagination';
import { playInstruction } from '../../../common/TabletFunctionality/playInstruction';

export const updateControllers = (info, globalSelf, localSelf) => {
  const self = globalSelf;
  function onSelectStart() {
    Pagination(this, self);
    if (!self.sound?.isPlaying) {
      if (self.model?.getObjectByName('1')) {
        playInstruction(
          this,
          self,
          self.props.text[self.props.text.length - 3]
        );
      } else if (self.model?.getObjectByName('2')) {
        playInstruction(
          this,
          self,
          self.props.text[self.props.text.length - 2]
        );
      } else if (self.model?.getObjectByName('3')) {
        playInstruction(
          this,
          self,
          self.props.text[self.props.text.length - 1]
        );
      }
    }
    if (this.userData.selected && !this.userData.attachedObject) {
      if (
        this.userData.selected.name == 'tabletSoundOn' &&
        self.props.description[self.props.lang] &&
        self.loadedMusicBar
      ) {
        textToSpeech(self);
        return 0;
      } else if (this.userData.selected.name == 'tabletSoundOff') {
        if (self.sound.isPlaying) {
          self.sound.pause();
          self.loadedMusicBar = true;
        }
        return 0;
      }
    }
    if (self.intersectsModel && self.intersectsModel.length > 0) {
      localSelf.intersectsPoint = self.intersectsModel[0];
    }
    localSelf.userData.selectPressed = true;
    localSelf.children[0].scale.z = 0;
    if (
      (localSelf.userData.selected?.name === 'tabletRefresh' ||
        localSelf.userData.selected?.name === 'Refresh') &&
      !this.userData.attachedObject &&
      !self.posModelX &&
      !self.posModelY &&
      !self.posModelZ &&
      !self.stopAttaching
    ) {
      self.model.getObjectByName('RefractionAmoniac').visible = false;
      self.model
        .getObjectByName('Flask')
        .getObjectByName('flaksAmoniac').visible = true;

      self.model
        .getObjectByName('Flask')
        .getObjectByName('flaksAmoniac').material.opacity = 0.15;

      self.scene.getObjectByName('Pippete').position.set(-0.535, 0.15, -0.001);
      self.scene.getObjectByName('Pippete').rotation.set(0.072, -1.42, 0.056);

      self.scene.getObjectByName('Flask').position.set(-0.064, 0.115, 0.012);
      self.scene.getObjectByName('Flask').rotation.set(0.072, -1.42, 0.05);
      self.props?.text?.map((element, index) => {
        self.model.remove(self.model.getObjectByName('3'));
        self.model.remove(self.model.getObjectByName('2'));
        self.model.remove(self.model.getObjectByName('1'));

        // self.room.remove(self.room.getObjectByName('parrent'));

        self.loadText(self.model, element, index);
      });
      return true;
    }
    if (
      localSelf.userData.selected !== undefined &&
      (localSelf.userData.selected.name === 'Flask' ||
        localSelf.userData.selected.name === 'Pippete') &&
      !self.posModelX &&
      !self.posModelY &&
      !self.posModelZ
    ) {
      self.posModelX = localSelf.userData.selected.position.x;
      self.posModelY = localSelf.userData.selected.position.y;
      self.posModelZ = localSelf.userData.selected.position.z;
      self.rotationModelX = localSelf.userData.selected.rotation.x;
      self.rotationModelY = localSelf.userData.selected.rotation.y;
      self.rotationModelZ = localSelf.userData.selected.rotation.z;
      self.createFlask(
        self.ammoClone,
        {
          x: localSelf.userData.selected.quaternion.x,
          y: localSelf.userData.selected.quaternion.y,
          z: localSelf.userData.selected.quaternion.z,
          w: localSelf.userData.selected.quaternion.w
        }, //quaternion
        {
          x: localSelf.userData.selected.position.x,
          y: localSelf.userData.selected.position.y,
          z: localSelf.userData.selected.position.z
        }, //position
        {
          x: localSelf.userData.selected.rotation.x,
          y: localSelf.userData.selected.rotation.y,
          z: localSelf.userData.selected.rotation.z
        }, //rotation
        {
          x: localSelf.userData.selected.scale.x,
          y: localSelf.userData.selected.scale.y,
          z: localSelf.userData.selected.scale.z
        }, //scale,
        localSelf.userData.selected
      );
      // localSelf.attach(localSelf.userData.selected);
      let controllerPos = new THREE.Vector3();
      let controllerQuaternion = new THREE.Quaternion();

      localSelf.children[1].getWorldPosition(controllerPos);
      localSelf.children[1].getWorldQuaternion(controllerQuaternion);

      let controllerPivot = new self.ammoClone.btVector3(0, 0, 0);
      let modelPivot = new self.ammoClone.btVector3(
        0,
        0,
        0
        // localSelf.children[1].position.z - 0.01
      );
      const symbols = ['-', '+', '+', '-'];

      if (localSelf.userData.selected.name === 'Flask') {
        self.createChemFlask(
          self.ammoClone,
          { x: 0, y: 0, z: 0, w: 1 }, //quat
          {
            x: -0.5,
            y: 0.05,
            z: 0
          }, //pos //-0.15
          { x: 0, y: 0, z: 0 }, //rotation
          { x: 0.05, y: 0.7, z: 0.1 },
          0xffff00,
          'chemPippete'
          // scale
        );
      } else if (localSelf.userData.selected.name === 'Pippete') {
        for (let i = 0; i < 4; i++) {
          self.createChemFlask(
            self.ammoClone,
            { x: 0, y: i % 2 === 0 ? 0 : 1, z: 0, w: 1 }, //quat
            {
              x: i % 2 === 0 ? symbols[i] + 0.09 : 0,
              y: 0.15,
              z: i % 2 !== 0 ? symbols[i] + 0.09 : 0
            }, //pos //-0.15
            { x: 0, y: 0, z: 0 }, //rotation
            { x: 0.02, y: 0.65, z: 0.2 },
            0xffff00,
            'chemFlaskCollision'
          );
        }
        self.createChemFlask(
          self.ammoClone,
          { x: 0, y: 0, z: 1, w: 1 }, //quat
          {
            x: 0,
            y: -0.12,
            z: 0
          }, //pos //-0.15
          { x: 0, y: 0, z: 0 }, //rotation
          { x: 0.05, y: 0.15, z: 0.15 },
          0xffff00,
          'chemFlaskCollision'
          // scale
        );
      }
      let controllerSphereBody = localSelf.children[1].userData.physicsBody;
      if (localSelf.p2p) {
        if (localSelf.userData.selected.name === 'Pippete') {
          const flaskBody =
            self.room.getObjectByName('Flask').userData.physicsBody;

          self.physicsWorld.removeRigidBody(flaskBody);
        } else if (localSelf.userData.selected.name === 'Flask') {
          const pippeteBody =
            self.room.getObjectByName('Pippete').userData.physicsBody;
          self.physicsWorld.removeRigidBody(pippeteBody);
        }
        self.physicsWorld.removeConstraint(localSelf.p2p);
      }
      localSelf.p2p = new self.ammoClone.btPoint2PointConstraint(
        controllerSphereBody,
        localSelf.userData.selected?.userData.physicsBody,
        controllerPivot,
        modelPivot
      );

      self.physicsWorld.addConstraint(localSelf.p2p, true);

      self.setState({
        modelName: localSelf.userData.selected.name,
        executed: true
      });

      localSelf.userData.attachedObject = localSelf.userData.selected;
    }
  }

  function onSelectEnd() {
    localSelf.userData.selectPressed = false;
    if (localSelf.userData.attachedObject !== undefined) {
      localSelf.remove(localSelf.userData.attachedObject);

      self.model.add(localSelf.userData.attachedObject);
      if (localSelf.userData.selected.name === 'Pippete') {
        localSelf.userData.selected.position.set(-0.535, 0.15, -0.001);
        localSelf.userData.selected.rotation.set(0.07, -1.42, 0.05);
      } else if (localSelf.userData.selected.name === 'Flask') {
        localSelf.userData.selected.position.set(-0.064, 0.115, 0.012);
        localSelf.userData.selected.rotation.set(0.07, -1.42, 0.05);
      }
      localSelf.userData.attachedObject = undefined;
      localSelf.userData.selected = undefined;
      localSelf.children[1].visible = false;
      localSelf.children[0].scale.z = 10;
      self.posModelX = undefined;
      self.posModelY = undefined;
      self.posModelZ = undefined;

      self.model.getObjectByName('pippeteAmoniac').scale.z = 0.01;
      self.model.getObjectByName('pippeteAmoniac').visible = false;
      self.model
        .getObjectByName('glassPippete')
        .material.color.setHex(0x4f4f4f);

      self.flagForFullPippete = false;
      if (self.dropletBackup) self.dropletBackup.visible = false;
    }
  }
  function onSqueezeStart() {
    localSelf.userData.squeezePressed = true;
  }

  function onSqueezeEnd() {
    localSelf.userData.squeezePressed = false;
  }

  function onDisconnected() {
    const index = localSelf.userData.index;

    if (self.controllers) {
      const obj = index == 0 ? self.controllers.right : self.controllers.left;

      if (obj) {
        if (obj.controller) {
          const controller = obj.controller;
          while (controller.children.length > 0)
            controller.remove(controller.children[0]);
          self.scene.remove(controller);
        }
        if (obj.grip) self.scene.remove(obj.grip);
      }
    }
  }

  if (info.left !== undefined && info.left.side === 'left') {
    const right = self.renderer.xr.getController(0);

    let trigger = false,
      squeeze = false;

    Object.keys(info.right).forEach((key) => {
      if (key.indexOf('trigger') != -1) trigger = true;
      if (key.indexOf('squeeze') != -1) squeeze = true;
    });

    if (trigger) {
      right.addEventListener('selectstart', onSelectStart);
      right.addEventListener('selectend', onSelectEnd);
    }

    if (squeeze) {
      right.addEventListener('squeezestart', onSqueezeStart);
      right.addEventListener('squeezeend', onSqueezeEnd);
    }

    right.addEventListener('disconnected', onDisconnected);
  }

  if (info.right !== undefined && info.right.side === 'right') {
    const left = self.renderer.xr.getController(1);

    let trigger = false,
      squeeze = false;

    Object.keys(info.left).forEach((key) => {
      if (key.indexOf('trigger') != -1) trigger = true;
      if (key.indexOf('squeeze') != -1) squeeze = true;
    });

    if (trigger) {
      left.addEventListener('selectstart', onSelectStart);
      left.addEventListener('selectend', onSelectEnd);
    }

    if (squeeze) {
      left.addEventListener('squeezestart', onSqueezeStart);
      left.addEventListener('squeezeend', onSqueezeEnd);
    }

    left.addEventListener('disconnected', onDisconnected);
  }
};
