/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { coursesData, coursesHeader } from '../../../assets/mockData/mockData';
import CategoryCard from './categoryCard/categoryCard';
import { useAppContext } from '../../../context/AppProvider';
import { ContentApi } from '../../../api/content.api';

const Categories = () => {
  const { store, setCategoryCollection } = useAppContext();
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const { selectedLanguage, categoryCollection } = store;

  return (
    <div
      className={
        'md px-5 py-10 pb-40 bg-white-bg flex flex-col items-center flex-grow'
      }
    >
      <div className={'columns-12 flex justify-center my-20'}>
        <p className={'text-5xl  text-center font-tinos font-bold text-dark'}>
          {coursesHeader[0].courseHeader[selectedLanguage]}
        </p>
      </div>
      <div
        className={
          'grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-rows-1 gap-6'
        }
      >
        {coursesData.map((course, index) => {
          return (
            <CategoryCard
              key={index}
              courseName={course.courseName[selectedLanguage]}
              coursePath={course.path}
              cardDescription={course.cardDescription[selectedLanguage]}
              courseBackground={course.courseBackground}
              courseDescription={course.courseDescription[selectedLanguage]}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Categories;
