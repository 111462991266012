import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';

import TopicRow from './topicRow';
import { useAppContext } from '../../../../../context/AppProvider';
import PositionChangerModal from './positionChangerModal';
import TopicDeleteConfirmation from './topicDeleteConfirmation';
import { ContentApi } from '../../../../../api/content.api';
import ResetTopicConfirmation from './resetTopicsConfirmation';
import { inputItems } from '../../../../../assets/mockData/mockData';

const values = [
  {
    val: 1,
    txt: 'Edit'
  },
  {
    val: 2,
    txt: 'Delete'
  },
  {
    val: 3,
    txt: 'Change Position'
  }
];

const TopicsPage = () => {
  const { store, setCategoryCollection, setShouldUpdate, logOutAction } =
    useAppContext();
  const { categoryCollection, selectedLanguage, shouldUpdate } = store;
  const history = useHistory();
  const params = useParams();
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    show: false,
    topicId: null
  });
  const [resetConfirmation, setResetConfirmation] = useState({
    show: false,
    topicId: null
  });
  const [showPositionChanger, setShowPositionChanger] = useState({
    show: false,
    topicId: null,
    categoryId: null,
    position: null
  });
  const [topics, setTopics] = useState([]);
  const token = window.localStorage.getItem('userToken');
  const userData = JSON.parse(window.localStorage.getItem('userData'));

  const logOut = () => {
    logOutAction();
    history.push('/');
  };

  useEffect(async () => {
    if (!categoryCollection?.categories?.length) {
      const collection = await ContentApi.getCategoryCollection(
        token,
        { parentId: userData.userId },
        history
      );

      if (collection?.categories?.categories?.length) {
        setCategoryCollection(collection.categories);
        return;
      }

      if (collection.message === 'No auth') {
        logOut();
      }
    }
  }, [categoryCollection]);

  useEffect(async () => {
    if (shouldUpdate) {
      const collection = await ContentApi.getCategoryCollection(
        token,
        { parentId: userData.userId },
        history
      );

      if (collection?.categories?.categories?.length) {
        setCategoryCollection(collection.categories);
        setShouldUpdate(false);
        return;
      }

      if (collection.status === 401) {
        logOut();
      }
    }
  }, [shouldUpdate]);

  useEffect(() => {
    if (categoryCollection?.categories?.length && params?.categoryId) {
      const selectedCategory = categoryCollection.categories.find(
        (cat) => cat.categoryId === params.categoryId
      );
      setTopics(selectedCategory.topicList);
    }
  }, [categoryCollection, params]);

  return (
    <div className={'flex flex-col align-middle p-5 h-full '}>
      <div className={'w-full'}>
        <span className={'text-xl font-medium underline decoration-2 '}>
          {history?.location?.state?.categoryName}
        </span>
      </div>
      <div
        className={'w-full mt-10 flex flex-col align-middle place-items-center'}
      >
        <div className="flex w-11/12 justify-end">
          <button
            onClick={() => {
              setResetConfirmation({
                show: true
              });
            }}
            className="w-full h-4/5 inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 bg-red-600 hover:bg-red-800 text-base font-medium text-white focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm place-items-center"
          >
            {inputItems.resetToDefault[selectedLanguage]}
          </button>

          <button
            onClick={() => {
              history.push({
                pathname: `/admin/content/${params.categoryId}/new_topic`,
                state: {
                  categoryId: params.categoryId,
                  categoryName: history?.location?.state?.categoryName,
                  mode: 'add'
                }
              });
            }}
            className="w-full h-4/5 inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm place-items-center"
          >
            <FontAwesomeIcon className={'mr-1'} icon={'plus'} size="1x" />
            {inputItems.addTopic[selectedLanguage]}
          </button>
        </div>
        <TopicDeleteConfirmation
          logOut={logOut}
          deleteConfirmation={deleteConfirmation}
          setDeleteConfirmation={setDeleteConfirmation}
          categoryId={params?.categoryId}
        />
        <ResetTopicConfirmation
          logOut={logOut}
          resetConfirmation={resetConfirmation}
          setResetConfirmation={setResetConfirmation}
          categoryId={params?.categoryId}
        />
        <PositionChangerModal
          setTopics={setTopics}
          topicList={topics}
          logOut={logOut}
          showPositionChanger={showPositionChanger}
          setShowPositionChanger={setShowPositionChanger}
          categoryId={params?.categoryId}
        />
        <div
          className={
            'w-11/12 mt-10 border-2 flex justify-between px-10 place-items-center'
          }
        >
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-3.5 cursor-pointer'
            }
          >
            {inputItems.topicName[selectedLanguage]}
          </div>
          <div
            className={
              'flex justify-center text-lg font-medium text-center w-3.5'
            }
          >
            {inputItems.options[selectedLanguage]}
          </div>
        </div>

        <div className={'w-11/12 mb-40 border flex flex-col align-middle'}>
          {topics?.map((topic, index) => {
            return (
              <TopicRow
                key={topic.topicId}
                topicName={topic.topicName[selectedLanguage] || topic.topicName}
                topicId={topic.topicId}
                setDeleteConfirmation={setDeleteConfirmation}
                setShowPositionChanger={setShowPositionChanger}
                options={values}
                categoryId={params.categoryId}
                categoryName={history?.location?.state?.categoryName}
                position={index + 1}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TopicsPage;
