import React, { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link, useHistory } from 'react-router-dom';

// import AuthContext from '../../context/authContext';
import { useHttp } from '../../hooks/useHttp';
import PopUp from '../../common/popUp';
import Dropdown from '../../common/Dropdown';
import { menuItems } from '../../assets/mockData/mockData';
import MobileMenu from '../../common/UserMenuMobile';
import { checkUserAuthentication } from '../../services/localStorage';
import { ContentApi } from './../../api/content.api';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
import '../../App.css';
import { useAppContext } from '../../context/AppProvider';

const AppHeader = () => {
  const {
    store,
    setSelectedLanguage,
    setUserAction,
    logOutAction,
    setCategoryCollection
  } = useAppContext();
  const { selectedLanguage, user } = store;

  const { request, apiUrl, resStatus, error } = useHttp();
  // const [user, setUser] = useState({});
  const [state, setState] = useState(false);

  const [open, setOpen] = useState(false);
  const navigate = useHistory();
  const navigation = [
    { name: menuItems.categories[selectedLanguage], href: '/categories' },
    { name: menuItems.aboutUs[selectedLanguage], href: '/aboutUs' },
    { name: menuItems.contactUs[selectedLanguage], href: '/contactUs' }
  ];
  const token = window.localStorage.getItem('userToken');
  const storageUser = JSON.parse(window.localStorage.getItem('userData'));
  const isAuthenticated = checkUserAuthentication();
  if (storageUser?.userId) {
    navigation.push({
      name: menuItems.myAccount[selectedLanguage],
      href: '/Profile'
    });
  }
  // const [setPremium] = useState(false);
  const languages = ['EN', 'RO'];
  const userToken = window.localStorage.getItem('userToken');

  const getId = async () => {
    if (storageUser?.userId) {
      try {
        const fetched = await request(
          apiUrl + `/api/auth/${storageUser?.userId}`,
          'GET',
          null,
          {
            Authorization: `${token}`
          },
          navigate
        );
        setUserAction(fetched);
      } catch (err) {
        console.log('Error occured....', err.message);

        if (err.message === 'No auth') {
          loginOut();
        }
      }
    }
  };

  const requestCategories = async (userId) => {
    const response = await ContentApi.getCategoryCollection(
      userToken,
      { parentId: userId },
      navigate
    );
    setCategoryCollection(response.categories);
  };
  useEffect(() => {
    if (user?.role && userToken) {
      if (user.role === 'school') {
        requestCategories(user.userId);
      } else if (user.role === 'learner') {
        requestCategories(user.parentId);
      }
    }
  }, [user, userToken]);

  // }, [token, userId, request]);
  const changeState = () => {
    setState(true);
  };
  const loginOut = () => {
    logOutAction();
    navigate.push('/');
  };

  const changeLanguage = (ln) => {
    window.localStorage.setItem('language', ln);
    setSelectedLanguage(ln);
  };

  const childToParent = (childData) => {
    setState(childData);
  };

  useEffect(() => {
    const lsLanguage = window.localStorage.getItem('language');
    if (!lsLanguage) {
      window.localStorage.setItem('language', 'EN');
      setSelectedLanguage('EN');
    } else {
      setSelectedLanguage(lsLanguage);
    }
  }, []);

  useEffect(() => {
    getId();
  }, []);
  useEffect(() => {
    setOpen(false);
  }, [navigate.location.pathname]);
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {() => (
        <>
          <div className="  px-2 sm:px-5 sm:pr-2  py-5 lg:px-8">
            <div className="relative flex items-center justify-between h-21">
              <div className="sm:absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center  rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none ">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon
                      onClick={() => setOpen(!open)}
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  ) : (
                    <MenuIcon
                      onClick={() => setOpen(!open)}
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <Link
                    to={'/'}
                    className="ml-7 flex items-center flex-shrink-0"
                  >
                    <h1 className="underline decoration-rose-400 font-tinos bg-black-900 text-3xl text-white">
                      Lectio
                    </h1>
                  </Link>
                  <div className="smmax:hidden flex items-center sm:ml-6 pt-1 ">
                    <div className="flex items-center space-x-3">
                      {navigation.map((item) => {
                        return (
                          <Link
                            to={item.href}
                            key={item.name}
                            href={item.href}
                            className={
                              'px-3 py-2 rounded-md text-white text-sm ,font-gothic flex items-center  text-gray hover:bg-gray-700 hover:text-white'
                            }
                          >
                            {item.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {!isAuthenticated ? (
                  <div className="flex smmax:hidden self-end  space-x-2 justify-self-end">
                    <Link to={'/signin'}>
                      <button className="btn   rounded-lg w-16 h-10  text-white">
                        {menuItems.signIn[selectedLanguage]}
                      </button>
                    </Link>
                    <Link to={'/signup'}>
                      <button className="btn bg-rose-400 text-white rounded-lg px-2 h-10">
                        {menuItems.signUp[selectedLanguage]}
                      </button>
                    </Link>
                  </div>
                ) : (
                  <div className="flex smmax:hidden">
                    <div className="flex  items-center mr-5 smmax:flex-col">
                      <button
                        onClick={changeState}
                        // onClick={() => setOpen(!open)}
                        type="button"
                        className={`w-full ${
                          navigate.location.pathname.split('/')[0] !==
                            'categories' &&
                          navigate.location.pathname.split('/').length != 4
                            ? 'hidden'
                            : null
                        } h-4/5 inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm`}
                      >
                        {menuItems.byPremium[selectedLanguage]}
                      </button>
                    </div>
                    <Dropdown
                      resStatus={resStatus}
                      errorMessage={error}
                      data={user}
                    />
                  </div>
                )}
              </div>
              {/* {premium ? (
                
              ) : null} */}
              {state ? <PopUp childToParent={childToParent} /> : null}

              <select
                // defaultValue="value"
                className={
                  'mt-1 w-11  ml-4    bg-gray-800 border-0 focus:outline outline-gray-800 text-white font-gothic  rounded-md   '
                }
                onChange={(e) => changeLanguage(e.target.value)}
              >
                <option hidden>{menuItems.Lang[selectedLanguage]}</option>
                {languages.map((ln, index) => {
                  return (
                    <option
                      defaultValue={ln === selectedLanguage}
                      className="focus:outline border-gray-800 outline-gray-800"
                      key={index}
                    >
                      {ln}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          {open ? (
            <div className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => {
                  return (
                    <Link
                      to={item.href}
                      key={item.name}
                      onClick={() => setOpen(false)}
                      className={classNames(
                        item.current
                          ? 'bg-gray-800 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Link>
                  );
                })}
                {storageUser?.userId ? (
                  <button
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                    onClick={loginOut}
                  >
                    {menuItems.logOut[selectedLanguage]}
                  </button>
                ) : null}
              </div>
              {!isAuthenticated ? (
                <div className="flex self-end flex-col space-y-3 p-2 justify-self-end">
                  <Link to={'/signin'}>
                    <button className="btn  rounded-lg w-24 h-10  text-white">
                      {menuItems.signIn[selectedLanguage]}
                    </button>
                  </Link>
                  <Link to={'/signup'}>
                    <button className="btn bg-rose-400 mb-2 rounded-lg text-white  w-24 h-10">
                      {menuItems.signUp[selectedLanguage]}
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="flex flex-col">
                  <MobileMenu data={user} childToParent={childToParent} />
                  <Link to={'pricing'} className="flex pb-5 smmax:flex-col">
                    <button
                      onClick={changeState}
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-1 p-2  ml-2 mt-2 mr-5 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none sm:h-10  smmax:w-36 w-32 sm:text-sm"
                    >
                      {menuItems.byPremium[selectedLanguage]}
                    </button>
                  </Link>
                </div>
              )}
              {state ? <PopUp childToParent={childToParent} /> : null}
            </div>
          ) : null}
        </>
      )}
    </Disclosure>
  );
};

export default AppHeader;
