/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { useHttp } from '../../../hooks/useHttp';
// import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// import AuthContext from '../../../context/authContext';
import Loader from '../../../common/loader';
import { menuItems } from '../../../assets/mockData/mockData';
import { useAppContext } from '../../../context/AppProvider';
const validRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
let timeDelay;

const SignUp = () => {
  // const auth = useContext(AuthContext);
  const { store, setUserAction, setUserTokenAction } = useAppContext();
  const { selectedLanguage } = store;
  const { request, loading, apiUrl } = useHttp();
  const [error, setError] = useState({ type: '', value: null });
  const [open, setOpen] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPassowordError] = useState('');
  const [form, setForm] = useState({
    email: '',
    password: ''
  });
  const navigate = useHistory();

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (document.getElementById('email-address').value)
      setForm({
        ...form,
        email: document.getElementById('email-address').value,
        password: document.getElementById('password').value
      });

    if (error.type) {
      timeDelay = setTimeout(() => {
        setError({ type: '', value: null });
        clearTimeout(timeDelay);
      }, 4000);
    }
  }, [error]);

  const loginHandler = async () => {
    if (passwordError || emailError) {
      return;
    }
    try {
      if (!checkEmptyObj(form)) {
        const data = await request(
          apiUrl + '/api/auth/login',
          'POST',
          {
            ...form
          },
          {},
          navigate
        );
        // auth.login(data.token, data.userId);
        setUserAction(data);
        setUserTokenAction(data.token);
        setForm({ ...form, email: '', password: '' });

        if (data) navigate.push('/');
      } else {
        setError({ type: 'Error', value: 'Please enter data' });
      }
    } catch (err) {
      console.log('Error occured...........', err);
      setForm({
        ...form,
        email: '',
        password: '',
        firstname: '',
        lastname: ''
      });
      setError({ type: 'Error', value: err.message });
    }
    setOpen(false);
  };
  const checkEmptyObj = () => {
    let isEmpty = true;
    for (let i in form) {
      if (form[i]) {
        return (isEmpty = false);
      }
    }
    return isEmpty;
  };
  const emailValidation = (value) => {
    if (!value) {
      setEmailError('Email is required.');
      return;
    }
    if (!value.match(validRegex)) {
      setEmailError('Invalid email address.');
      return;
    }

    setEmailError('');
  };
  const passwordValidation = (value) => {
    if (!value) {
      setPassowordError('Password is required.');
      return;
    }

    setPassowordError('');
  };
  return (
    <>
      <div className=" relative grow min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        {/* <div className="w-full h-20 bg-black absolute">

          </div> */}
        <div className="max-w-md w-full space-y-8">
          <div
            className={` border  transition ease-in-out smmax:w-3/4 xsmax:w-3/4  duration-100 ${
              error.value === null || open ? 'hidden ' : ''
            }  ${
              error.type === 'Passed'
                ? 'border-green-400 text-green-700 bg-green-100 '
                : 'border-red-400 text-red-700 bg-red-100 '
            } translate-x-16 -mt-16 right-20 absolute  flex justify-between px-6   py-3 rounded `}
            role="alert"
          >
            <div className="flex flex-col smmin:pr-32">
              <strong className="font-bold  ">
                {error.type === 'error' ? error.type : error.type}
              </strong>
              <span className="block sm:inline ">{error.value}</span>
            </div>
            <span className="justify-end self-end  flex pl-3  py-3">
              <svg
                onClick={
                  (() => setOpen(true),
                  () => setError({ type: '', value: null }))
                }
                className={`self-end fill-current h-6 w-6 ${
                  error.type === 'Passed' ? 'text-green-500' : 'text-red-500 '
                } `}
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>

          <div>
            <h1 className="mx-auto h-12 font-extrabold text-gray-700 text-5xl text-center">
              Lectio
            </h1>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {menuItems.signIn.info[selectedLanguage]}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600"></p>
          </div>
          <form className="mt-8 space-y-6">
            <input
              disabled={loading}
              type="hidden"
              name="remember"
              defaultValue="true"
            />
            <div className="space-y-3 rounded-md shadow-sm ">
              <div>
                <label
                  htmlFor="email-address"
                  className=" p-1 text-gray-700 font-bold text-sm"
                >
                  {menuItems.email[selectedLanguage]}
                </label>
                <input
                  disabled={loading}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onBlur={(event) => emailValidation(event.target.value)}
                  required
                  onChange={changeHandler}
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                    emailError ? 'border-red-600' : 'border-gray-300'
                  } placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                  placeholder={menuItems.email[selectedLanguage]}
                />
                {emailError && (
                  <span className={'text-red-600 text-sm'}>{emailError}</span>
                )}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className=" p-1 text-gray-700 font-bold text-sm"
                >
                  {menuItems.password[selectedLanguage]}
                </label>
                <input
                  disabled={loading}
                  id="password"
                  name="password"
                  type="password"
                  onBlur={(event) => passwordValidation(event.target.value)}
                  autoComplete="current-password"
                  required
                  onChange={changeHandler}
                  className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${
                    passwordError ? 'border-red-600' : 'border-gray-300'
                  } placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                  placeholder={menuItems.password[selectedLanguage]}
                />
                {passwordError && (
                  <span className={'text-red-600 text-sm'}>
                    {passwordError}
                  </span>
                )}
              </div>
            </div>

            <div className="flex items-center w-full  justify-between">
              <div className="text-sm">
                <a
                  href="/forgot_password"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </a>
              </div>
              {/* <Link to={'/signup'} className="text-sm">
                <p className="font-medium self-end text-indigo-600 mr-5 hover:text-indigo-500">
                  {menuItems.signUp[selectedLanguage]}
                </p>
              </Link> */}
            </div>

            <div>
              {loading ? (
                <div>
                  <Loader />
                </div>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    loginHandler();
                  }}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900  "
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                  {menuItems.signIn[selectedLanguage]}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
