import * as THREE from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';

import { instructions } from './tabletText';
import { instructionsVoiceSettings } from './tabletText';

import { instructionsWithoudSlider } from './tabletText';
export const Tablet = (self, controllers) => {
  self.nextPage = false;
  self.prevPage = false;
  self.counterForPagination = false;
  self.indexOfVisiblePage = 0;
  self.indexOfBlockerPage = 0;

  const loadTablet = (leftController, self) => {
    self.tabletNoSliderDesk = [];
    self.tabletInstructionRefresh = [];
    self.tabletInstructionRefreshGroup = new THREE.Group();
    self.tabletInstructionRefreshGroup.name = 'tabletInstructionRefresh';
    self.tabletDescription = [];
    self.tabletDescriptionGroup = new THREE.Group();
    self.tabletGroups = [];
    self.tabletDescriptionGroup.name = 'tabletDescription';

    self.tabletDescriptionSlider = [];
    const draco = new DRACOLoader();

    const loader = new GLTFLoader();
    draco.setDecoderConfig({ type: 'js' });
    // const s3BucketUrl = `https://s3.eu-central-1.amazonaws.com/lectio.app/models/${this.props.type}`;
    let model;
    if (self.props?.name?.withoutDeskFlag) {
      model = require('../../assets/noSliderTablet.glb');
    } else if (self.props.name?.withoutDeskAndInstructionFlag) {
      model = require('../../assets/noSliderAndInstrucitonTablet.glb');
    } else {
      model = require('../../assets/tablet.glb');
    }

    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );
    loader.setDRACOLoader(draco);
    loader.load(
      //   s3BucketUrl,
      model,
      function (gltf) {
        gltf.scene.name = 'tablet';
        leftController.add(gltf.scene);
        self.tablet = gltf.scene;
        self.model = gltf.scene;
        self.model.add(self.tabletInstructionRefreshGroup);
        self.model.add(self.tabletDescriptionGroup);

        if (self.props.name?.withoutDeskFlag) {
          self.tabletInstructionRefresh.push(
            self.model.getObjectByName('tabletRefresh'),
            self.model.getObjectByName('tabletRefreshIcon'),
            self.model.getObjectByName('tabletInstructionSoundOff'),
            self.model.getObjectByName('tabletInstructionSoundOn'),
            self.model.getObjectByName('tabletInstructionSoundIcons')
          );
          self.tabletDescription.push(
            self.model.getObjectByName('tabletSoundOff'),
            self.model.getObjectByName('tabletSoundOn'),
            self.model.getObjectByName('tabletSoundIcons')
          );

          self.tabletNoSliderDesk = [
            ...self.tabletInstructionRefresh,
            ...self.tabletDescription
          ];
          self.tabletInstructionRefresh.map((element) => {
            self.tabletInstructionRefreshGroup.add(element);
          });
          self.tabletDescription.map((element) => {
            self.tabletDescriptionGroup.add(element);
          });
          self.tabletDescriptionGroup.visible = false;
          self.tabletGroups.push(
            self.tabletInstructionRefreshGroup,
            self.tabletDescriptionGroup
          );
        } else if (!self.props.name?.withoutDeskFlag) {
          self.tabletDescriptionSlider.push(
            self.model.getObjectByName('tabletInstructionSoundOn'),
            self.model.getObjectByName('tabletInstructionSoundOff'),
            self.model.getObjectByName('tabletUpModel'),
            self.model.getObjectByName('tabletDownModel')
          );
        }
        gltf.scene.position.set(-0.5, -0.2, 0.8);
        if (self.props.name?.withoutDeskFlag)
          instructionsWithoudSlider.forEach((element) => {
            loadText(self.tablet, element);
          });
        else if (self.props.name?.withoutDeskAndInstructionFlag)
          instructionsVoiceSettings.forEach((element) => {
            loadText(self.tablet, element);
          });
        else
          instructions.forEach((element) => {
            loadText(self.tablet, element);
          });
      }
    );
  };
  const loadText = async (tablet, text) => {
    const loader = new FontLoader();

    await loader.load(
      '/helvetiker_regular.typeface.json',
      function (font) {
        var textGeometry = new TextGeometry(text[self.props.lang], {
          font: font,
          size: text.modelSize ? text.modelSize : 0.1,
          height: text.modelHeight ? text.modelHeight : 0.03
        });
        let textMaterial;
        let mesh = new THREE.Mesh(textGeometry, textMaterial);
        mesh.text = true;
        if (text.attachTo) {
          self.model.getObjectByName(text.attachTo).add(mesh);
        } else self.tablet.add(mesh);
        if (text.modelCoordsX && text.modelCoordsY && text.modelCoordsZ) {
          mesh.position.set(
            text.modelCoordsX,
            text.modelCoordsY,
            text.modelCoordsZ
          );
        } else {
          mesh.position.set(0, 0, 0);
        }
        if (text.modelRotationX && text.modelRotationY && text.modelRotationZ) {
          mesh.rotation.set(
            text.modelRotationX,
            text.modelRotationY,
            text.modelRotationZ
          );
        } else {
          mesh.rotation.set(0, 0, 0);
        }
      }

      // onError callback
    );
  };
  const leftController = controllers[0];
  loadTablet(leftController, self);
};
