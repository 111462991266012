/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry';
import styles from './styles.css';
import { VRButton } from '../../../../common/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import { Link } from 'react-router-dom';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { withRouter } from 'react-router-dom';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { roundedSquare } from '../../../../common/SceneModelGeneral/roundedSquareBg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SceneModelGeneral extends Component {
  constructor(props) {
    super(props);
    this.lang = props.lang;
    this.containerRef = React.createRef();
    this.materials = {};
    this.state = {
      progress: true,
      loadedFlag: false,
      percent: 0,
      stateFlag: false,
      modelPosition: [],
      modelName: '',
      language: 5,
      flagFullscreen: false,
      loadingType: props.type
    };
    this.flagForClearScene = true;
    const imageBg = require('../../../../assets/img/gradientScene.jpeg');
    this.clock = new THREE.Clock();
    this.scene = new THREE.Scene();
    this.scene.background = this.backgroundTexture;
    this.camera = new THREE.PerspectiveCamera(
      60,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );

    this.camera.position.set(-0.6, 0.8, 2.5);

    this.scene.add(this.camera);

    const texture = new THREE.TextureLoader().load(imageBg);

    this.scene.background = texture;
    this.modelPosZ = 0;
    this.modelPosY = 0;
    this.modelPosX = 0;
    this.lineLength = 0;

    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      powerPreference: 'high-performance'
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.setSize(window.innerWidth, window.innerHeight);

    this.ambientLight = new THREE.HemisphereLight(0xffffff);
    this.scene.add(this.ambientLight);
    this.directionalLight = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight.position.set(3, 3, 3).normalize();
    this.directionalLight2 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight2.position.set(5, 5, 5).normalize();
    this.directionalLight3 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight3.position.set(5, 5, -5).normalize();
    this.directionalLight4 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight4.position.set(-7, -5, -5).normalize();
    this.directionalLight5 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight5.position.set(-5, 0, 0).normalize();
    this.directionalLight6 = new THREE.DirectionalLight(0xffffff, 3);
    this.directionalLight6.position.set(0, -5, 0).normalize();

    this.scene.add(this.directionalLight);
    this.scene.add(this.directionalLight2);
    this.scene.add(this.directionalLight3);
    this.scene.add(this.directionalLight4);
    this.scene.add(this.directionalLight5);
    this.scene.add(this.directionalLight6);
    this.start_x;
    this.start_y;

    this.raycaster = new THREE.Raycaster();
    this.workingMatrix = new THREE.Matrix4();
    this.workingVector = new THREE.Vector3();
    this.origin = new THREE.Vector3();

    this.dolly = new THREE.Object3D();
    this.dolly.position.z = 5;
    this.scene.add(this.dolly);
    this.dummyCam = new THREE.Object3D();
    this.camera.add(this.dummyCam);

    this.model;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.update();
    this.controls.maxPolarAngle = Math.PI * 0.5;
    this.controls.minDistance = 1;
    this.controls.maxDistance = 10;

    this.renderer.setAnimationLoop(this.render.bind(this));

    this.initScene();
    this.setupVr(this.scene);
  }

  initScene() {
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    } else {
      this.room = new THREE.LineSegments(
        new BoxLineGeometry(0, 0, 0, 0, 0, 0),
        new THREE.LineBasicMaterial({ color: 0x808080 })
      );
    }
    this.scene.add(this.room);
    this.room.name = 'room';
  }

  loadModel(room, file) {
    const draco = new DRACOLoader();
    const self = this;
    this.flagForClearScene = false;
    const loader = new GLTFLoader();
    draco.setDecoderConfig({ type: 'js' });
    const model = new THREE.Mesh();
    model.name = 'importedModel';
    draco.setDecoderPath(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/draco/'
    );
    loader.setDRACOLoader(draco);
    loader.load(
      file,
      function (gltf) {
        gltf.scene.children[0].position.set(0, 0.8, 3.5);
        model.add(gltf.scene);
        room.add(model);
        self.model = gltf.scene;

        if (gltf?.animations.length) {
          self.mixer = new THREE.AnimationMixer(gltf.scene);
          const clips = gltf?.animations;
          clips.forEach(function (clip) {
            self?.mixer?.clipAction(clip)?.play();
          });
        }
      },
      function (xhr) {
        self.setState({
          ...self.state,
          percent: (xhr.loaded / xhr.total) * 100
        });
      },
      function (err) {
        console.log('error========>', err);
      }
    );
  }

  async loadText(room, text) {
    const loader = new FontLoader();
    const self = this;
    await loader.load(
      'https://s3.eu-central-1.amazonaws.com/lectio.app/helvetiker_regular.typeface.json',
      function (font) {
        var textGeometry = new TextGeometry(text[self.props.lang], {
          font: font,
          size: text.modelSize ? text.modelSize : 0.1,
          height: text.modelHeight ? text.modelHeight : 0.03
        });
        let textMaterial;

        var mesh = new THREE.Mesh(textGeometry, textMaterial);
        room.add(mesh);
        if (self.props.textType != 'static') {
          if (room.getObjectByName('parrent') && text.modelType == 'parrent') {
            room.getObjectByName('parrent').name = 'text';
            room.getObjectByName('children').name = 'text';
            mesh.name = 'parrent';
          } else if (
            text.modelType == 'parrent' &&
            !room.getObjectByName('parrent')
          ) {
            mesh.name = 'parrent';
          } else if (text.modelType == 'children') {
            mesh.name = 'children';
          } else {
            mesh.name = 'text';
          }

          if (text.modelType === 'children') {
            room.getObjectByName('parrent').add(mesh);
          }
        }
        if (text.modelCoordsX && text.modelCoordsY && text.modelCoordsZ) {
          mesh.position.set(
            text.modelCoordsX,
            text.modelCoordsY,
            text.modelCoordsZ
          );
        } else {
          mesh.position.set(0, 0, 0);
        }
        if (text.modelRotationX && text.modelRotationY && text.modelRotationZ) {
          mesh.rotation.set(
            text.modelRotationX,
            text.modelRotationY,
            text.modelRotationZ
          );
        } else {
          mesh.rotation.set(0, 0, 0);
        }
      }

      // onProgress callback

      // onError callback
    );
  }

  setupVr(scene) {
    this.renderer.xr.enabled = true;
    const self = this;
    this.controllers = this.buildControllers(scene);
    function onConnected(e) {
      this.gamepad = e.data.gamepad;
      self.updateControllers({
        right: { trigger: true, squeeze: true },
        left: { trigger: true, squeeze: true }
      });
    }

    const controllers = [
      this.renderer.xr.getController(0),
      this.renderer.xr.getController(1)
    ];

    controllers.map((element) => {
      element.addEventListener('connected', onConnected);
    });
  }

  updateControllers(info) {
    const self = this;

    function onSelectStart() {
      this.userData.squeezePressed = true;
      if (
        this.userData.selected !== undefined &&
        !self.posModelX &&
        !self.posModelY &&
        !self.posModelZ
      ) {
        self.posModelX = this.userData.selected.position.x;
        self.posModelY = this.userData.selected.position.y;
        self.posModelZ = this.userData.selected.position.z;
        self.rotationModelX = this.userData.selected.rotation.x;
        self.rotationModelY = this.userData.selected.rotation.y;
        self.rotationModelZ = this.userData.selected.rotation.z;

        this.attach(this.userData.selected);
        self.setState({
          modelName: this.userData.selected.name,
          executed: true
        });

        this.userData.attachedObject = this.userData.selected;
      }
    }

    function onSelectEnd() {
      this.userData.squeezePressed = false;
      if (this.userData.attachedObject !== undefined) {
        this.remove(this.userData.attachedObject);

        self.model.add(this.userData.attachedObject);
        self.model
          .getObjectByName(self.state.modelName)
          .position.set(self.posModelX, self.posModelY, self.posModelZ);
        self.model
          .getObjectByName(self.state.modelName)
          .rotation.set(
            self.rotationModelX,
            self.rotationModelY,
            self.rotationModelZ
          );
        this.userData.attachedObject = undefined;
        this.userData.selected = undefined;
        this.children[1].visible = false;
        this.children[0].scale.z = 10;
        self.posModelX = undefined;
        self.posModelY = undefined;
        self.posModelZ = undefined;
        self.posModelX = undefined;
        self.posModelY = undefined;
        self.posModelZ = undefined;
      }
    }

    function onDisconnected() {
      const index = this.userData.index;

      if (self.controllers) {
        const obj = index == 0 ? self.controllers.right : self.controllers.left;

        if (obj) {
          if (obj.controller) {
            const controller = obj.controller;
            while (controller.children.length > 0)
              controller.remove(controller.children[0]);
            self.scene.remove(controller);
          }
          if (obj.grip) self.scene.remove(obj.grip);
        }
      }
    }

    if (info.right !== undefined) {
      const right = this.renderer.xr.getController(0);

      let trigger = false;
      Object.keys(info.right).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
      });

      if (trigger) {
        right.addEventListener('selectstart', onSelectStart);
        right.addEventListener('selectend', onSelectEnd);
      }

      right.addEventListener('disconnected', onDisconnected);
    }

    if (info.left !== undefined) {
      const left = this.renderer.xr.getController(1);

      let trigger = false;
      Object.keys(info.left).forEach((key) => {
        if (key.indexOf('trigger') != -1) trigger = true;
      });

      if (trigger) {
        left.addEventListener('selectstart', onSelectStart);
        left.addEventListener('selectend', onSelectEnd);
      }

      left.addEventListener('disconnected', onDisconnected);
    }
  }

  buildControllers() {
    const controllerModelFactory = new XRControllerModelFactory();
    const geometrySphere = new THREE.IcosahedronBufferGeometry(this.radius, 2);

    const sphere = new THREE.Mesh(
      geometrySphere,
      new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.BackSide })
    );
    sphere.scale.set(0.01, 0.01, 0.01);

    const geometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, -1)
    ]);

    const line = new THREE.Line(geometry);
    line.name = 'line';
    line.scale.z = 2;
    sphere.visible = false;
    const controllers = [];

    for (let i = 0; i <= 1; i++) {
      const controller = this.renderer.xr.getController(i);

      controller.add(line.clone());
      controller.add(sphere.clone());
      controller.userData.selectPressed = false;

      this.dolly.add(controller);

      controllers.push(controller);

      const grip = this.renderer.xr.getControllerGrip(i);
      grip.add(controllerModelFactory.createControllerModel(grip));

      this.workingMatrix.identity().extractRotation(controller.matrixWorld);

      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);

      controller.children[0].scale.z = 10;

      this.dolly.add(grip);
    }

    return controllers;
  }
  handleController(controller, dt, index) {
    // if(index===1){
    const axes = controller.gamepad?.axes;
    // }
    // else{

    // }
    const speed = 0.03;

    // const wallLimit = 1.5;

    let a = 0;
    let b = 0;

    if (controller.userData.selectPressed) {
      const speed = index === 0 ? 5 : -5;
      const quaternion = this.dolly.quaternion.clone();
      this.dolly.quaternion.copy(this.camera?.quaternion);
      this.dolly.translateZ(-dt * speed);

      this.dolly.quaternion.copy(quaternion);
    }
    if (controller.userData.squeezePressed) {
      this.workingMatrix.identity().extractRotation(controller.matrixWorld);
      this.raycaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
      this.raycaster.ray.direction
        .set(0, 0, -1)
        .applyMatrix4(this.workingMatrix);
      let intersectsModel;

      intersectsModel = this.raycaster.intersectObjects(
        this.room.getObjectByName('AuxScene').children
      );

      if (intersectsModel.length > 0) {
        intersectsModel[0].object.add(this.model.children);
        controller.children[0].scale.z = intersectsModel[0].distance;
        this.lineLength = intersectsModel[0].distance;
        if (intersectsModel[0].object.parent.name === '')
          controller.userData.selected = intersectsModel[0].object.parent;
        else if (
          intersectsModel[0].object.name.indexOf('mesh') === 0 &&
          intersectsModel[0].object.parent.name.indexOf('link') === -1
        ) {
          controller.userData.selected =
            intersectsModel[0].object.parent.parent;
        } else {
          controller.userData.selected =
            intersectsModel[0].object.parent.parent.parent;
        }
        controller.children[1].visible = true;
        controller.children[1].position.x = controller.children[0].position.x;
        controller.children[1].position.z = -intersectsModel[0].distance + 0.03;
      } else {
        controller.children[0].scale.z = 10;
        controller.children[1].visible = false;
      }
      if (controller.userData.attachedObject) {
        controller.children[1].visible = true;
        controller.children[0].scale.z = this.lineLength;
      }
    }

    //=================> movement <=================

    if (axes?.length) {
      if (axes[2] != 0) {
        a += axes[2];
      }
      if (axes[3] != 0) {
        b += axes[3];
      }
    }

    const quaternion = this.dolly.quaternion.clone();

    if (a !== 0 || b !== 0) {
      this.dolly.quaternion.copy(this.camera?.quaternion);
      this.dolly.translateZ(b * speed);
      this.dolly.translateX(a * speed);

      this.dolly.quaternion.copy(quaternion);
    }
  }

  componentDidUpdate() {
    if (this.props.file && this.flagForClearScene) {
      this.loadModel(this.room, this.props.file);
    }
  }

  componentDidMount() {
    roundedSquare(this.scene, this.props);
    // this.button = document.getElementById('vrButton');
    this.containerRef?.current?.appendChild(this.renderer.domElement);
    if (!document.getElementById('vrButton'))
      this.vrButton = new VRButton(this.renderer);
    const self = this;

    //When user turn on the VR mode.
    self.renderer.xr.addEventListener('sessionstart', function () {
      self.dolly.add(self.camera);
      self.dolly.position.z = 2;
      self.dolly.position.y = -0.5;
      self.dolly.position.x = 0;
    });

    //When user turn off the VR mode.
    self.renderer.xr.addEventListener('sessionend', function () {
      self.dolly.remove(self.camera);
      self.dolly.position.z = 2.5;
      self.dolly.position.y = 0.8;
      self.camera.position.set(0, 0.8, 2.5);
    });
    window.addEventListener('resize', this.resize());
  }

  componentWillUnmount() {
    this.containerRef?.current?.removeChild(this.renderer.domElement);
    window.removeEventListener('resize', this.resize);

    !this.props.isPreview && this?.vrButton?.hideEnterVR();
  }

  shouldComponentUpdate(nextProps, nextState) {
    this.lang = nextProps.lang;

    //Setting coordinates for room (model)
    if (
      this.props.roomCoordsZ &&
      this.props.roomCoordsX &&
      this.props.roomCoordsY
    ) {
      this.room.position.set(
        this.props.roomCoordsX,
        this.props.roomCoordsY,
        this.props.roomCoordsZ
      );
    } else {
      this.room.position.set(-0.8, 0, 0);
    }
    //Setting rotation coords for rotation (model)
    if (
      this.props.roomRotationZ &&
      this.props.roomRotationX &&
      this.props.roomRotationY
    ) {
      this.room.rotation.set(
        this.props.roomRotationX,
        this.props.roomRotationY,
        this.props.roomRotationZ
      );
    }
    //Setting scaling for room (model)
    if (
      this.props.roomScaleZ &&
      this.props.roomScaleX &&
      this.props.roomScaleY
    ) {
      this.room.scale.set(
        this.props.roomScaleX,
        this.props.roomScaleY,
        this.props.roomScaleZ
      );
    }
    //Setting camera position in scene
    if (
      this.props.cameraCoordsZ &&
      this.props.cameraCoordsX &&
      this.props.cameraCoordsY
    ) {
      this.camera.position.set(
        this.props.cameraCoordsX,
        this.props.cameraCoordsY,
        this.props.cameraCoordsZ
      );
    }

    //Setting coord for text in scene

    //Setting loading
    if (nextProps.type !== this.state.loadingType) {
      this.setState({
        ...this.state,
        loadingType: nextProps.type
      });
      return true;
    }

    if (this.state.progress !== nextState) {
      return true;
    }
    if (nextProps.z !== this.props.z) {
      return true;
    } else {
      return false;
    }
  }
  restorePage(self) {
    self.room?.clear();
    this.flagForClearScene = true;
  }
  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  render() {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
    const dt = this.clock.getDelta();
    this.room.children.forEach((element) => {
      if (element.name === 'text' || element.name == 'parrent')
        element.quaternion?.copy(this.camera.quaternion);
    });
    if (this.vrButton) this.vrButton.vrButton.disabled = false;
    if (this.controllers) {
      const self = this;
      this.controllers.forEach((controller, index) => {
        self.handleController(controller, dt, index);
      });
    }

    this.mixer?.update(dt);

    return (
      <div
        ref={this.containerRef}
        className={`${styles.sceneContainer} overflow-hidden`}
      >
        {this.props.isPreviewContainer && this.state.flagFullscreen ? (
          <Link
            onClick={this.updateState}
            className={
              'px-2 absolute py-2 bg-gray-500 border-2 border-white rounded-lg text-center hover:bg-gray-600 hover hover:border-gray-100 top-5 right-5 text-white '
            }
          >
            {' '}
            <svg
              className={'self-end fill-current h-6 w-6 '}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Exit Fullscreen</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </Link>
        ) : null}
        <Link
          to={'/admin/moleculeConstructor'}
          className="px-2 absolute  py-2 rounded-lg text-center  bottom-80  left-6 xsmax:right-4  text-white"
        >
          <FontAwesomeIcon
            icon={'arrow-alt-circle-left'}
            className={'self-end  fill-current h-12 w-12 '}
            size="lg"
          />
        </Link>
        <button
          onClick={() => this.restorePage(this)}
          className="px-2 absolute  py-2 rounded-lg text-center  bottom-3 mb-0.5 right-24 xsmax:right-10  text-white"
        >
          <FontAwesomeIcon
            icon={'trash'}
            className={'self-end  fill-current h-6 w-6 '}
            size="lg"
          />
        </button>
      </div>
    );
    // }
  }
}

export default withRouter(SceneModelGeneral);
