const ApiUtils = {
  checkStatus: async (response, history) => {
    if (response.status !== 200 && response.status !== 201) {
      console.log('---- Error catch in api utils', response.error);
      // Alert.alert(response.error.message);
      if (response.status === 401) {
        await window.localStorage.removeItem('userData');
        await window.localStorage.removeItem('userToken');
        history?.push('/signin');
      }
      //   notifyMessage(response.error.message, response.error.details);
      throw response.status;
    } else {
      return response;
    }
  },
  getBaseUrl: () =>
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:8000/api'
      : 'https://lectio-staging-api.herokuapp.com/api'
};

export { ApiUtils as default };
