import { React, useState, useEffect } from 'react';
import ModelViewer from './ModelViewer';
import './styles.css';
import Wrapper from '../../../../common/SceneModelGeneral/wrappper';
const ModelViewerPage = () => {
  const [file, setFile] = useState('');
  useEffect(() => {
    document.getElementById('myFile').onchange = function (event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.srcElement.files[0]);
      reader.onload = function () {
        var fileContent = reader.result;
        setFile(fileContent);
      };
    };
  }, [document.getElementById('myFile')]);

  return (
    <div className=" w-full overflow-hidden   flex-grow p-10 ">
      <h1 className="font-gothic  text-2xl text-center py-2 ">
        Drag in the window model that you created and exported in the preivous
        step
      </h1>
      <form
        action="/"
        className=" centered-element cursor-pointer font-tinos z-10   "
      >
        <input
          className="px-2  absolute 
           py-2 rounded-lg text-center bottom-0 lg:bottom-24  left-6 xsmax:right-4	"
          type="file"
          id="myFile"
          value=""
          title=" "
          name="filename"
        ></input>
        {/* <button>Select File</button> */}
      </form>
      {}
      <div
        id={'contentScene'}
        className={
          'border  w-11/12 mx-auto p-1 my-4 flex relative  justify-center items-end '
        }
      >
        <Wrapper>
          <div className="  flex-grow  h-800   overflow-hidden z-1   bg-center flex justify-center">
            <ModelViewer file={file} />
          </div>
        </Wrapper>
      </div>
    </div>
  );
};
export default ModelViewerPage;
